"use client";
import dynamic from "next/dynamic";
import { useCallback } from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
const MenuItems = dynamic(() => import("./MenuItems"));

export default function Menu({
  white = false,
  menuItems = [],
  visible = false,
  onChangeVisible,
}) {
  const handleCollapse = useCallback(() => {
    onChangeVisible && onChangeVisible(!visible);
  }, [onChangeVisible, visible]);

  return (
    <>
      <button
        className={`block p-1 text-2xl ${
          white ? "text-dark hover:bg-gray-custom" : "text-white hover:bg-gray-light"
        } border-none rounded-full outline-none laptop:hidden`}
        onClick={handleCollapse}
        aria-label="Menu"
      >
        <AiOutlineMenuUnfold className={`${!visible ? "block" : "hidden"}`} />
        <AiOutlineMenuFold className={`${!visible ? "hidden" : "block"}`} />
      </button>
      <button
        className={`fixed top-0 left-0 z-20 w-screen h-screen min-h-screen transition-all min-w-screen bg-dark/40 duration-500 ${
          !visible ? "invisible opacity-0" : "opacity-1"
        }`}
        onClick={handleCollapse}
        tabIndex={visible ? 0 : -1} // Add tabIndex attribute
      />
      <div
        className={`fixed w-[290px] bg-white/95 top-0 h-screen min-h-screen flex flex-col items-start z-20 overflow-hidden justify-start duration-500 transition-all shadow-md ${
          !visible ? "invisible -left-full" : "left-0"
        }`}
      >
        <button
          className="flex items-center justify-center p-1 my-2 ml-2 overflow-hidden rounded-full w-9 h-9 bg-dark min-w-[36px] min-h-[36px] outline-none border-none"
          onClick={handleCollapse}
        >
          <AiOutlineMenuFold className={`block text-white`} size={20} />
        </button>
        <MenuItems
          menuItems={menuItems}
          className="w-full h-full max-h-full overflow-hidden bg-white"
          onClick={handleCollapse}
        />
      </div>
    </>
  );
}
