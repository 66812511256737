"use client";
// import dynamic from "next/dynamic";
import { useEffect, useMemo, useState } from "react";
import {
  AiOutlineContacts,
  AiOutlineHome,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsFilePost, BsInfoCircle } from "react-icons/bs";
import useFetch from "~/api/useFetch";
import { usePathname } from "next/navigation";
import ContactBar from "./ContactBar";
import Menu from './Menu';
import NavBar from "./NavBar";
import SearchBar from "./SearchBar";

// const ContactBar = dynamic(() => import("./ContactBar"));
// const Menu = dynamic(() => import("./Menu"));
// const NavBar = dynamic(() => import("./NavBar"));
// const SearchBar = dynamic(() => import("./SearchBar"));

function getItem(
  label,
  path,
  icon,
  children = null,
  visible = true,
  visibleChildren = true
) {
  return {
    path,
    icon,
    children,
    label,
    visible,
    visibleChildren,
  };
}

function getCategoryItem(item, parentPath) {
  const path = (parentPath || "") + "/" + item.slug;

  return {
    path,
    icon: null,
    children: item.children?.length
      ? item.children.map((childItem) => getCategoryItem(childItem, path))
      : null,
    label: item.name,
    visible: item.visible,
    visibleChildren: item.visible_children,
  };
}

const postItems = [
  {
    slug: "blogs",
    name: "Bài viết",
    visible: true,
  },
  {
    slug: "projects",
    name: "Dự án của chúng tôi",
    visible: true,
  },
];

export default function HeaderBar() {
  const { data } = useFetch(`/categories?page=1&page_size=500`);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleSticky, setVisibleSticky] = useState(false);
  const pathname = usePathname();

  const menuItems = useMemo(() => {
    return [
      getItem("Trang chủ", "/", <AiOutlineHome size={20} />),
      getItem(
        "Sản phẩm",
        "/products",
        <AiOutlineShoppingCart size={20} />,
        [...(data?.data || [])].map((category) => getCategoryItem(category))
      ),
      getItem("Về chúng tôi", "/about", <BsInfoCircle size={19} />),
      getItem(
        "Tin tức",
        "/posts",
        <BsFilePost size={20} />,
        postItems.map((postItem) => getCategoryItem(postItem, "/posts"))
      ),
      getItem("Liên hệ", "/contact", <AiOutlineContacts size={21} />),
    ];
  }, [data]);

  const white = useMemo(() => {
    const exception = ["/posts/blogs", "/posts/projects"];
    for (const path of exception) {
      if (pathname.startsWith(path)) {
        return false;
      }
    }

    const allow = ["/search", "/products/"];
    for (const path of allow) {
      if (pathname.startsWith(path)) {
        return true;
      }
    }

    return false;
  }, [pathname]);

  useEffect(() => {
    if (window.scrollY > 150) {
      setVisibleSticky(true);
    }
    window.onscroll = () => {
      if (window.scrollY > 150) {
        setVisibleSticky(true);
      } else {
        setVisibleSticky(false);
      }
    };
  }, []);

  return (
    <>
      <div className={`absolute top-0 left-0 z-10 flex flex-col items-center justify-start w-full bg-transparent bg-no-repeat bg-cover bg-origin-content h-fit`}>
        <ContactBar />
        <div
          className={`flex items-center justify-between w-full px-4 py-4 ${
            white ? "shadow-md" : ""
          } h-fit desktop:px-32 desktop-xl:px-48`}
        >
          <div className="inline-block">
            <Menu
              menuItems={menuItems}
              white={white}
              visible={visibleMenu}
              onChangeVisible={setVisibleMenu}
            />
            <NavBar
              menuItems={menuItems}
              white={white}
            />
          </div>
          <SearchBar white={white} />
        </div>
      </div>
      <div
        className={`fixed left-0 z-20 flex items-center justify-between w-screen px-4 py-2 tablet:py-1 bg-white shadow-md min-w-fit min-h-fit h-fit desktop:px-32 desktop-xl:px-48 transition-all duration-200 ${
          visibleSticky ? "top-0" : "-top-full"
        }`}
      >
        <div className="inline-block">
          <Menu
            // menuItems={isLoading ? defaultMenuItems : menuItems}
            menuItems={menuItems}
            white={true}
            visible={visibleMenu}
            onChangeVisible={setVisibleMenu}
          />
          <NavBar
            menuItems={menuItems}
            white={true}
          />
        </div>
        <SearchBar white={true} />
      </div>
    </>
  );
}
