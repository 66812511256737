"use client";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { useCallback, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import Image from "next/image";
import Link from "~/_components/atoms/Link";
const Dropdown = dynamic(() => import("./Dropdown"));

const NavBar = ({ menuItems = [], white = false }) => {
    const pathname = usePathname();
    const [dropdown, setDropdown] = useState(false);

    const getClassName = useCallback(
        (item) => {
            let className =
                "group box-content relative h-5 py-2.5 m-3 text-base font-semibold cursor-pointer w-fit transition-all flex flex-row items-center justify-center min-w-fit";
            if (!white) {
                className += " hover:text-gold-dark";
            } else {
                className += " hover:text-gold";
            }
            if ((item.path !== "/" && pathname.includes(item.path)) || (pathname === "/" && item.path === "/")) {
                if (!white) {
                    className += " text-gold-dark [&>.my-border]:opacity-100";
                } else {
                    className += " text-gold [&>.my-border]:opacity-100";
                }
            } else if (!white) {
                    className += " text-white";
                } else {
                    className += " text-dark";
                }
            return className;
        },
        [white, pathname]
    );

    const handleMouseEnter = useCallback((item) => {
        if (item.children && item.visibleChildren) {
            setDropdown(item.path);
        }
    }, []);

    const handleCloseDropdown = useCallback((item) => {
        if (item.children && item.visibleChildren) {
            setDropdown(false);
        }
    }, []);

    return (
        <div className="relative flex-row items-center justify-between hidden laptop:flex">
            <div className="w-[72px]">
                <Link href="/" prefetch={false}>
                    <Image
                        width={72}
                        height={72}
                        src={white ? "/logo-tron-dark.svg" : "/logo-tron-light.svg"}
                        alt="da-my-nghe-thanh-dat"
                        priority
                        unoptimized
                        unselectable="on"
                    ></Image>
                </Link>
            </div>
            {menuItems
                .filter((item) => item.visible)
                .map((item, index) => (
                    <div className="text-normal" key={`nav-bar-item-${index}`}>
                        <button
                            className={getClassName(item)}
                            onMouseEnter={() => handleMouseEnter(item)}
                            onMouseLeave={() => handleCloseDropdown(item)}
                            onClick={() => handleCloseDropdown(item)}
                        >
                            <Link href={item.path} prefetch={true} ariaLabel={item.label} className="w-fit">
                                {item.label}
                            </Link>
                            <div
                                className={`ml-2 rotate-90 group-hover:rotate-[270deg] transition-all duration-300 ${
                                    item.children && item.visibleChildren ? "block" : "hidden"
                                }`}
                            >
                                <AiOutlineRight />
                            </div>
                            {item.children && item.visibleChildren && (
                                <Dropdown childrens={item.children} dropdown={dropdown === item.path} />
                            )}
                            <div
                                className={`my-border rounded-full border-none absolute w-full h-0.5 top-full left-0 ${
                                    !white ? "bg-gold-dark" : "bg-gold"
                                } opacity-0 box-content group-hover:opacity-100 transition-all`}
                            />
                        </button>
                    </div>
                ))}
        </div>
    );
};

export default NavBar;
