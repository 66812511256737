"use client";
import Script from "next/script";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

const Map = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const iframeRef = useRef(null);

  useEffect(() => {
    if (inView && iframeRef?.current) {
      iframeRef.current.src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA9ZsWiSdvJHhAufrRJ7HOuPL4LzW-XpIM&q=Đá+Mỹ+Nghệ+Thành+Đạt+Ninh+Bình&zoom=16&language=vi";
    }
  }, [inView, iframeRef]);

  return (
    <div
      className="w-full h-full overflow-hidden laptop:pl-5 laptop:w-7/12 laptop:pb-0"
      ref={inViewRef}
    >
      <Script strategy="afterInteractive" id="fix-scroll-warning">
        {`
          function addPrefetch(e, t, i) {
            const a = document.createElement("link");
            (a.rel = e), (a.href = t), i && (a.as = i), document.head.append(a);
          }

          addPrefetch("preconnect", "https://maps.gstatic.com");
          addPrefetch("preconnect", "https://maps.googleapis.com");
          `}
      </Script>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 0 }}
        loading="lazy"
        title="map"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src=""
        id="google-maps-embed"
        ref={iframeRef}
      ></iframe>
    </div>
  );
};

export default Map;
