"use client";
import useSWR from "swr";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function useFetch(url, overrideFetcher) {
  return useSWR(
    url ? process.env.currentUrl + url : null,
    overrideFetcher || fetcher,
    {
      revalidateOnFocus: false,
    }
  );
};