"use client";
import { useCallback } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";

export default function ScrollToTopButton() {
  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <button
      className="cursor-pointer absolute w-14 h-14 tablet:w-16 tablet:h-16 bg-gold rounded-full z-10 text-3xl tablet:text-4xl text-white font-bold translate-x-[-50%] translate-y-[-50%] top-0 left-[50%] flex items-center justify-center"
      aria-label="scroll-to-top-button"
      onClick={handleScrollToTop}
    >
      <AiOutlineArrowUp />
    </button>
  );
}
