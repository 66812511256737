import { AiFillYoutube } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { LuMail, LuPhoneCall } from "react-icons/lu";

const ContactBar = () => {
  return (
    <div
      className={`relative bg-gold w-full flex-row justify-between overflow-hidden text-white hidden h-45 py-2 desktop:px-32 desktop-xl:px-48 px-4 tablet:flex whitespace-nowrap`}
    >
      <div className="relative flex flex-row items-center w-fit [&>*:not(:first-child)]:ml-4">
        <a
          className="flex flex-row items-center text-white cursor-pointer hover:text-dark hover:underline"
          href="mailto:damynghethanhdatninhbinh@gmail.com"
        >
          <LuMail className="w-5 h-5 mr-2" size={20} />
          <span className="text-sm">damynghethanhdatninhbinh@gmail.com</span>
        </a>
        <a
          className="flex flex-row items-center text-white cursor-pointer hover:text-dark hover:underline"
          href="tel:0899812205"
        >
          <LuPhoneCall className="w-5 h-5 mr-2" size={19} />
          <span className="text-sm">0899 812 205</span>
        </a>
      </div>
      <div className="relative w-fit flex flex-row items-center [&>*:not(:first-child)]:ml-4">
        <a href="https://www.facebook.com/damynghethanhdatnb" className="flex flex-row items-center text-white cursor-pointer hover:text-dark hover:underline">
          <BsFacebook size={18} className="w-5 h-5 mr-2" />
          <span className="text-sm">damynghethanhdatnb</span>
        </a>
        <a href="https://www.youtube.com/@damynghethanhdatninhbinh" className="flex flex-row items-center text-white cursor-pointer hover:text-dark hover:underline">
          <AiFillYoutube size={20} className="w-5 h-5 mr-2" />
          <span className="text-sm hidden laptop:block">@damynghethanhdatninhbinh</span>
        </a>
      </div>
    </div>
  );
};

export default ContactBar;
