export default async function fetchApi(url, options) {
  const data = await fetch(
    process.env.backendUrl + url,
    Object.assign(options || {}, { next: { revalidate: 600 } })
    // Object.assign(options || {}, { cache: "no-store" })
  );

  try {
    const parsedData = await data.json();
    return parsedData;
  } catch (error) {
    throw {
      data,
      error,
    };
  }
}
