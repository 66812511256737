"use client";
import { Suspense } from "react";
import { useCallback, useState, useEffect } from "react";
import { AiOutlineSearch } from 'react-icons/ai';
import { useRouter, useSearchParams } from "next/navigation";

function SearchBarFallback({ white = false }) {
  return (
    <div
      className={`flex items-center rounded-full justify-between ${
        white ? "bg-dark/10" : "bg-white/25"
      } w-[280px] h-[45px] overflow-hidden`}
    >
      <label
        htmlFor={`search-id-${white ? "sticky" : "normal"}-fallback`}
        className="box-border flex items-center w-full h-full px-4 py-0 m-0 align-middle transition-all shadow-none appearance-none max-w--full touch-manipulation text-ellipsis"
      >
        <input
          id={`search-id-${white ? "sticky" : "normal"}-fallback`}
          placeholder="Nhập từ khoá tìm kiếm..."
          className={`w-full overflow-hidden bg-transparent border-none outline-none h-fit text-ellipsis ${
            !white
              ? "placeholder:text-white focus:text-white text-white"
              : "placeholder:text-[#7d7d7d] focus:text-[#7d7d7d]"
          }`}
        />
      </label>
      <button
        className="ripple relative min-w-[45px] min-h-[45px] flex justify-center items-center w-[45px] h-[45px] bg-gold rounded-full font-bold text-base text-white outline-none border-none"
        title="Search Btn Fallback"
      >
        <AiOutlineSearch />
      </button>
    </div>
  );
}

function LoadSearchBar({ white = false }) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const value = searchParams.get("value");
    if (value !== null && value !== searchValue) {
      setSearchValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleInputSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    router.push(`/search?value=${searchValue}`);
  }, [router, searchValue]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  return (
    <div
      className={`flex items-center rounded-full justify-between ${
        white ? "bg-dark/10" : "bg-white/25"
      } w-[280px] h-[40px] overflow-hidden`}
    >
      <label
        htmlFor={`search-id-${white ? "sticky" : "normal"}`}
        className="box-border flex items-center flex-grow h-full px-4 py-0 m-0 align-middle transition-all shadow-none appearance-none order-0 max-w--full touch-manipulation text-ellipsis"
      >
        <input
          id={`search-id-${white ? "sticky" : "normal"}`}
          onChange={handleInputSearch}
          value={searchValue}
          placeholder="Nhập từ khoá tìm kiếm..."
          onKeyDown={handleKeyDown}
          autoComplete="on"
          className={`w-full overflow-hidden bg-transparent border-none outline-none h-fit text-ellipsis ${
            !white
              ? "placeholder:text-white focus:text-white text-white"
              : "placeholder:text-[#7d7d7d] focus:text-[#7d7d7d]"
          }`}
        />
      </label>
      <button
        className="order-1 ripple relative flex justify-center items-center w-[40px] h-[40px] bg-gold rounded-full font-bold text-base text-white outline-none border-none"
        onClick={handleSearch}
        title="Search Btn"
      >
        <AiOutlineSearch />
      </button>
    </div>
  );
};

export default function SearchBar({ white = false }) {
  return (
    <Suspense fallback={<SearchBarFallback white={white} />}>
      <LoadSearchBar white={white} />
    </Suspense>
  );
}
