"use client";
import { useCallback, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { MdError } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import fetchApi from "~/api/fetchServer";

const ContactForm = () => {
  const [infoInput, setInfoInput] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState({});

  const handleChange = useCallback(
    (e) => {
      if (!e.target) return;
      if (errorMessage[e.target.name]) {
        setErrorMessage({ ...errorMessage, [e.target.name]: null });
      }

      setInfoInput({ ...infoInput, [e.target.name]: e.target.value || "" });
    },
    [errorMessage, infoInput]
  );

  const handleSubmit = useCallback(() => {
    const errMsgs = {};

    for (const [key, value] of Object.entries(infoInput)) {
      if (key === "name") {
        const specialCharacterRegex = /^[A-Za-zÀ-ỹ\s]+$/;
        if (!specialCharacterRegex.test(value) || value === "") {
          errMsgs[key] = "Tên không hợp lệ!";

          toast.error(errMsgs[key], 3);
        }
      }

      if (key === "phone") {
        const numberRegex = /^((\+84|84|0|0084)[35789])\d{8}$/;
        if (!numberRegex.test(value) || value === "") {
          errMsgs[key] = "Số điện thoại không hợp lệ!";

          toast.error(errMsgs[key], 3);
        }
      }

      if (key === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(value) || value === "") {
          errMsgs[key] = "Email không hợp lệ!";

          toast.error(errMsgs[key], 3);
        }
      }
    }
    setErrorMessage(errMsgs);

    if (!Object.values(errMsgs)?.length) {
      toast.success(
        "Xin cảm ơn, yêu cầu hỗ trợ của bạn đã được gửi thành công!"
      );

      fetchApi("/requests", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...infoInput, note: "None" }),
      });
    }
  }, [infoInput]);

  return (
    <div className="flex flex-col w-full pb-4 laptop:w-5/12 h-fit laptop:pb-0">
      <h2 className="mb-4 text-xl font-bold text-center tablet:text-xl desktop:text-2xl text-dark">
        Bạn cần hỗ trợ?
      </h2>
      <p className="text-sm font-normal text-justify laptop:text-base text-dark">
        Trong suốt hơn 10 năm làm nghề, sự hài lòng của khách hàng luôn là thước đo sự thành công của chúng tôi.
      </p>
      <p className="text-sm font-normal text-justify laptop:text-base text-dark">
        Đá mỹ nghệ Thành Đạt luôn lắng nghe, tiếp thu, thay đổi để ngày càng phục vụ khách hàng được tốt hơn.
      </p>
      <p className="text-sm font-normal text-justify laptop:text-base text-dark">
        Nếu khách hàng có bất cứ câu hỏi, thắc mắc, góp ý hoặc cần chúng tôi tư vấn, hỗ trợ xin hãy liên hệ với chúng tôi qua hotline: 0899 812 205, hay qua Zalo, Facebook hoặc điền thông tin vào mẫu bên dưới.
      </p>
      <p className="text-sm font-normal text-justify laptop:text-base text-dark">
        Đá mỹ nghệ Thành Đạt hân hạnh được phục vụ quý khách!
      </p>
      <div className="flex flex-col overflow-hidden bg-white border rounded-lg border-gold my-2.5">
        <label
          htmlFor="name"
          className="text-base text-dark h-fit outline-none px-2.5 py-1.5 border-b border-gold relative flex flex-row items-center"
        >
          <input
            id="name"
            placeholder="Họ và tên"
            name="name"
            className="w-full text-base border-none outline-none"
            onChange={handleChange}
            autoComplete="on"
          ></input>
          <MdError
            className={`relative min-w-fit ml-2 text-[tomato] ripple ${
              errorMessage.name ? "visible" : "invisible"
            }`}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={errorMessage.name}
          />
        </label>
        <label
          htmlFor="phone"
          className="text-base text-dark h-fit outline-none px-2.5 py-1.5 border-b border-gold relative flex flex-row items-center"
        >
          <input
            id="phone"
            placeholder="Số điện thoại"
            name="phone"
            className="w-full text-base border-none outline-none"
            onChange={handleChange}
            autoComplete="on"
          ></input>
          <MdError
            className={`relative min-w-fit ml-2 text-[tomato] ripple ${
              errorMessage.name ? "visible" : "invisible"
            }`}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={errorMessage.phone}
          />
        </label>
        <label
          htmlFor="email"
          className="text-base text-dark h-fit outline-none px-2.5 py-1.5 relative flex flex-row items-center"
        >
          <input
            id="email"
            placeholder="Email"
            name="email"
            className="w-full text-base border-none outline-none"
            onChange={handleChange}
            autoComplete="on"
          ></input>
          <MdError
            className={`relative min-w-fit ml-2 text-[tomato] ripple ${
              errorMessage.name ? "visible" : "invisible"
            }`}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={errorMessage.email}
          />
        </label>
      </div>
      <div className="flex items-center justify-center w-full">
        <button
          className="ripple btn-secondary !font-bold !py-2"
          onClick={handleSubmit}
        >
          Yêu cầu tư vấn
        </button>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          duration: 4000,
          style: {
            background: "white",
            color: "black",
            fontSize: "14px",
            padding: "4px 8px",
            borderRadius: "8px",
            width: "fit-content",
            whiteSpace: "nowrap",
          },
        }}
      />
      <Tooltip id="my-tooltip" />
    </div>
  );
};

export default ContactForm;
